import "./App.scss";
import  { FC, Suspense, lazy } from "react"; // Import Suspense from React
import { BrowserRouter as Router } from "react-router-dom";
import { Provider as RollbarProvider } from "@rollbar/react";
import { useTranslation } from "react-i18next";

// Views/Containers
const MainRouter = lazy(() => import('./mainRouter'));
const GTMProvider = lazy(() => import('@elgorditosalsero/react-gtm-hook').then(
  module => ({ default: module.GTMProvider })
));

// Stylesheets--

const App: FC = () => {
  const { i18n } = useTranslation();
  const gtmParams = { id: process.env.REACT_APP_GTM_KEY || "" };
  const rollbarConfig = {
    accessToken: process.env.REACT_APP_ROLLBAR_TOKEN,
    environment: process.env.REACT_APP_ENVIRONMENT,
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload: {
      client: {
        javascript: {
          code_version: "1.0.0",
          source_map_enabled: true,
        },
      },
    },
  };

  return (
    <div className={`parent-wrapper ${i18n.language}`}>
      <RollbarProvider config={rollbarConfig}>
        <Suspense fallback={<></>}>
          <GTMProvider state={gtmParams}>
            <Router>
              <MainRouter />
            </Router>
          </GTMProvider>
        </Suspense>
      </RollbarProvider>
    </div>
  );
};

export default App;
